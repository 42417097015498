import { Alert, flashAlert } from '@maternity/mun-alert';
import {
  EmailInput,
  ErrorMessage,
  Form,
  Submit,
  useForm,
} from '@maternity/mun-forms';
import { Col, LinkButton, PanelBody } from '@maternity/mun-layout';
import { goto } from '@maternity/mun-router';
import { injector } from '@maternity/ng-mun-linear';
import * as React from 'react';

import { Footer } from '../chrome';
import { Routes } from '../types';

type PasswordResetMsg = Routes['password_request_reset']['message_in'];

export const PasswordReset = () => {
  const form = useForm<PasswordResetMsg>();
  const handleSubmit = React.useCallback((data: PasswordResetMsg) => {
    const munDocIO = injector.get('munDocIO');
    const io = munDocIO.mkDocSaver('password_request_reset');
    return io(data).then(() => {
      goto('login');
      flashAlert({
        title: 'Success!',
        content: 'Check your email for the confirmation link.',
        type: 'success',
        duration: 15,
      });
    });
  }, []);

  return (
    <React.Fragment>
      <div id="main" className="container-fluid login">
        <Form form={form} onSubmit={handleSubmit} blockUnloadWhenDirty={false}>
          <Col md={4} mdOffset={4} className="login-form">
            <div className="panel-login">
              <PanelBody>
                <Alert variant="info">
                  Please enter your username or email address. You will receive
                  a link to create a new password via email.
                </Alert>
                <ErrorMessage
                  form={form}
                  model="email"
                  className="alert alert-danger"
                />
                <div className="form-group">
                  <EmailInput
                    form={form}
                    model="email"
                    className="input-lg"
                    required={true}
                    placeholder="Email"
                    autoFocus={true}
                  />
                </div>
                <div className="form-group">
                  <Submit block={true} size="lg">
                    Get new password
                  </Submit>
                </div>
                <br />
                <br />
                <p className="text-center">
                  <small>
                    <LinkButton tag="button" sref="login" variant="link">
                      ‹ Go back, I remember now
                    </LinkButton>
                  </small>
                </p>
              </PanelBody>
            </div>
          </Col>
        </Form>
      </div>
      <Footer />
    </React.Fragment>
  );
};
