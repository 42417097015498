const ngModule = angular.module('mun-bootstrap', [])

  .config(function($provide) {
    // Save a copy of uncompiled html.
    $provide.decorator('$rootElement', ['$delegate', function($delegate) {
        $delegate.data('uncompiled', $delegate.clone());
        return $delegate;
      }]);
  })

  .factory('reboot', function($log, $rootElement, $rootScope, $timeout) {
    return reboot;

    function reboot(module, args) {
      var $rootElementCopy = $rootElement.data('uncompiled');
      if (!$rootElementCopy) {
        $log.warn('rebooted already!');
        return;
      }

      $timeout(function() {
          $log.log('rebooting into', module, 'bye!');
          // Angular $destroy event doesn't hook replaceWith().
          $rootElement.triggerHandler('$destroy');
          $rootElement.replaceWith($rootElementCopy);
          // Remove any watchers, as they may conflict.
          $rootScope.$destroy();
          $rootScope.$$watchers = [];
          angular.bootstrap($rootElementCopy[0], [initArgs].concat(module), {
            strictDi: true,
          });
        }, 0, false);

      initArgs.$inject = ['$provide'];
      function initArgs($provide) {
        Object.keys(args)
          .forEach(function(k) {
            $provide.constant(k, args[k]);
          });
      }
    }
  })

  ;

export default ngModule.name;
