import {
  EmailInput,
  ErrorMessage,
  Form,
  PasswordInput,
  Submit,
  useForm,
  useFormState,
} from '@maternity/mun-forms';
import { LinkButton, PanelBody, PanelHeading } from '@maternity/mun-layout';
import { GetIOMessageIn } from '@maternity/mun-router';
import { useMunSession } from '@maternity/mun-session';
import * as React from 'react';

import { Footer } from './chrome';

type LoginMsg = GetIOMessageIn<'accountSessionIO.login'>;

const errorMessages = {
  required: 'Email and password are required.',
  failed: 'Sorry, your email or password is incorrect.',
};

export const Login = () => {
  const munSession = useMunSession();
  const form = useForm<LoginMsg>();
  const submitCount = useFormState(
    form,
    React.useCallback((s) => s.submitCount, []),
  );
  const handleSubmit = React.useCallback(
    (data: LoginMsg) =>
      munSession.$login(data.username, data.password).catch((resp: any) => {
        if (resp?.status === 401) {
          // Pretend an auth failure is a server-side validation error
          resp = {
            status: 400,
            data: {
              invalid: {
                password: {
                  _self: [{ err_id: 'failed' }],
                },
              },
            },
          };
        }
        return Promise.reject(resp);
      }),
    [munSession],
  );

  return (
    <React.Fragment>
      <div id="main" className="login">
        <Form
          form={form}
          className="login-form"
          onSubmit={handleSubmit}
          blockUnloadWhenDirty={false}
        >
          <div className="panel-login">
            <PanelHeading>
              <h1 className="text-center">Welcome</h1>
            </PanelHeading>
            <PanelBody>
              {submitCount > 0 && (
                <ErrorMessage
                  form={form}
                  model={['username', 'password']}
                  errorMessages={errorMessages}
                  className="alert alert-danger"
                />
              )}
              <div className="form-group">
                <EmailInput
                  form={form}
                  model="username"
                  className="input-lg"
                  required={true}
                  placeholder="Email"
                  autoFocus={true}
                />
              </div>
              <div className="form-group">
                <PasswordInput
                  form={form}
                  model="password"
                  className="input-lg"
                  required={true}
                  placeholder="Password"
                />
              </div>
              <div className="form-group">
                <Submit block={true} size="lg">
                  Sign In
                </Submit>
                <br />
                <p className="text-center">
                  <small>
                    <LinkButton
                      tag="button"
                      sref="passwordreset"
                      variant="link"
                    >
                      I can't log in.
                    </LinkButton>
                  </small>
                </p>
              </div>
            </PanelBody>
          </div>
        </Form>
      </div>
      <Footer />
    </React.Fragment>
  );
};
