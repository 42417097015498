import munBootstrapModule from '@maternity/ng-mun-bootstrap';
import { react2angular } from 'react2angular';

import { Login } from '../Login';
import { PasswordReset } from '../password/PasswordReset';
import kerbinCoreModule from './kerbin-core';

const ngModule = angular.module('kerbin-authstrap', [
    kerbinCoreModule,
    munBootstrapModule,
  ])

  .config(function($stateProvider) {
    $stateProvider

      .state('login', {
        views: {
          page: {
            template: '<kerbin-login />',
          },
        },
      })

      .state('passwordreset', {
        views: {
          page: {
            template: '<kerbin-password-reset />',
          },
        },
      })
      ;
  })

  .run(function(munSession, $state, reboot, $log, accountSessionIO) {
    munSession.$whenAuthenticated
      .then(function() { return accountSessionIO.session(); })
      .then(function(accountSessionResp) {
        const features = munSession.practice.effective_features;
        let promises;

        try {
          // Dynamically import modules based on mode, apps, and features
          if (munSession.mode === 'staff') {
            promises = [
              import(
                /* webpackChunkName: 'kerbin-provider' */
                './kerbin-provider'),
              features.includes('dres') && import(
                /* webpackChunkName: 'dres-staff' */
                '@maternity/dres/app/js/ng/dres-staff'),
              features.includes('duna') && import(
                /* webpackChunkName: 'duna-provider' */
                '@maternity/duna/app/js/ng/duna-library/provider'),
              features.includes('eve') && import(
                /* webpackChunkName: 'eve-provider' */
                '@maternity/eve/app/js/ng/eve-provider'),
              features.includes('jool_lite') && import(
                /* webpackChunkName: 'jool-lite-provider' */
                '@maternity/jool/app/js/ng/jool-lite-provider'),
              features.includes('jool_encounters') && import(
                /* webpackChunkName: 'jool-encounter-provider' */
                '@maternity/jool/app/js/ng/encounters/jool-encounter-provider'),
              features.includes('kearth') && import(
                /* webpackChunkName: 'kearth-provider' */
                '@maternity/kearth/app/js/ng/kearth-provider'),
              features.includes('kearth') && import(
                /* webpackChunkName: 'eve-lite-provider' */
                '@maternity/eve/app/js/ng/eve-lite-provider'),
              features.includes('payment_ledger') && import(
                /* webpackChunkName: 'payment-ledger-provider' */
                '@maternity/eve/app/js/ng/lite-provider/payments'),
              features.includes('moho_forms') && import(
                /* webpackChunkName: 'moho-provider' */
                '@maternity/moho/app/js/ng/moho-provider'),
              (features.includes('kearth') ||
                features.includes('problems_medications_allergies')) &&
                import(
                  /* webpackChunkName: 'probmedal-staff' */
                  '@maternity/kearth/app/js/ng/probmedal/staff'),
              // Uncomment these lines to enable the stylist app.
              // import(
              //   /* webpackChunkName: 'stylist' */
              //   '@maternity/stylist/app/js/ng/stylist'),
            ];
          } else if (munSession.mode === 'client') {
            promises = [
              import(
                /* webpackChunkName: 'kerbin-patient' */
                './kerbin-patient'),
              features.includes('duna') && import(
                /* webpackChunkName: 'duna-patient' */
                '@maternity/duna/app/js/ng/duna-library/patient'),
              features.includes('ike') && import(
                /* webpackChunkName: 'ike-patient' */
                '@maternity/ike/app/js/ng/ike-patient'),
              features.includes('jool_lite') && import(
                /* webpackChunkName: 'jool-lite-client' */
                '@maternity/jool/app/js/ng/jool-lite-client'),
              features.includes('jool_encounters') && import(
                /* webpackChunkName: 'jool-encounter-client' */
                '@maternity/jool/app/js/ng/encounters/jool-encounter-client'),
              features.includes('kearth') && import(
                /* webpackChunkName: 'kearth-patient' */
                '@maternity/kearth/app/js/ng/kearth-patient'),
              (features.includes('kearth') ||
                // eve-lite-patient only contains the payment ledger
                features.includes('payment_ledger')) &&
                import(
                  /* webpackChunkName: 'eve-lite-patient' */
                  '@maternity/eve/app/js/ng/eve-lite-patient'),
              (features.includes('kearth') ||
                  features.includes('problems_medications_allergies')) &&
                  import(
                    /* webpackChunkName: 'probmedal-client' */
                    '@maternity/kearth/app/js/ng/probmedal/client'),
            ];
          } else {
            throw new Error(`Unknown mode: ${munSession.mode}`);
          }

          // Condense the array
          promises = promises.filter((p) => p);

          // Wait for all the async imports to finish, then reboot
          Promise.all(promises)
            .then((modules) => reboot(modules.map((m) => m.default), {
              MUN_PROFILE_SESSION: munSession.$raw,
              MUN_ACCOUNT_SESSION: accountSessionResp.data.item.$raw,
            }))
            .catch(handleError);
        } catch (e) {
          handleError(e);
        }

        function handleError(e) {
          $log.error('authstrap getApp() failed: ', e);
          // TODO: modal error?  $state.goto('noapp')?
          $state.goto('logout');
        }
      });

    munSession.$whenReady
      .finally(function() {
        if (!munSession.$authenticated)
          $state.goto('login');
      });
  })

  .component('kerbinLogin', react2angular(Login))

  .component('kerbinPasswordReset', react2angular(PasswordReset))
  ;

export default ngModule.name;
